<template>
	<div class="home">
		<div id="content" class="home-content blue-box curved" style="--accent: #00a7e5">
			<svg width="500" height="200" viewBox="0 0 500 200" preserveAspectRatio="none">
				<path d="M0,150 L0,26 Q250,-16 500,26 L500,150 Q250,110 0,150Z" fill="#cee7f0"/>

				<path d="M0,28 L0,24 Q250,-15 500,24 L500,28 Q250,-12 0,28Z" fill="#00a7e5"/>
				<path d="M0,151 L0,147 Q250,108 500,147 L500,151 Q250,111 0,151Z" fill="#00a7e5"/>
			</svg>

            <div class="content-wide centered-banner">
				<p class="banner-title">{{ title }}</p>
				<p class="subtitle-small">{{ subtitle }}</p>
            </div>
		</div>
		
		<div id="content">
			<div class="resources-home-video" v-if="showVideo">
				<div style="padding:56.21% 0 0 0;position:relative;" class="video-container">
					<iframe v-if="videoURL" :src="`https://player.vimeo.com/video/${videoURL}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Encore Methodology"></iframe>
				</div>

				<input v-model="videoURLEdit" v-if="isAdmin"/>
				
				<input v-if="isAdmin" v-model="videoCaptionEdit"/>
				<p class="info-small" v-else>
					{{ videoCaption }}
				</p>
			</div>

			<div class="content-wide" v-if="isAdmin">
				<div class="admin-control-box">
					<div class="admin-row">
						<p class="heading-2">Access Groups</p>
						<button class="control-button material-icons" @click="addAccessGroup">add</button>
					</div>

					<div class="access-groups-container">
						<div class="admin-row" v-for="group in accessGroups" :key="group.id">
							<p class="access-group">{{ group.name }}</p>
							<button class="control-button material-icons" @click="editAccessGroup(group)">edit</button>
							<button class="control-button material-icons" @click="deleteAccessGroup(group)">delete</button>
						</div>
					</div>

					<button class="button-round" v-if="isAdmin && selectedSubCollectionId == null" @click="addCollection">Add Collection</button>
				</div>
			</div>
		</div>

		
		<div v-if="selectedSubCollectionId">
			<div class="video-detail-banner" :style="{'--custom-colour': selectedCollection.colour }" id="resource-content">
				<p>{{ collectionTitle }}</p>
				<button class="button-round" @click="back">Back</button>
			</div>

			<div id="content" class="home-content">
				<div class="collection-detail-list" :style="{'--custom-colour': selectedCollection.colour }">
					<p class="collection-detail-title" v-if="!isEditingSubCollectionName">{{ selectedSubCollection.name }}</p>
					<input class="collection-detail-title" v-else v-model="subCollectionNameEdit">

					<div class="controls subcollection" v-if="isAdmin">
						<button class="control-button material-icons" @click="editSubCollectionName">{{ isEditingSubCollectionName ? 'done' : 'edit'}}</button>
						<button class="control-button material-icons" @click="removeSubCollection(selectedSubCollectionId).then(back())">delete</button>
						<button class="control-button material-icons" @click="addResource">add</button>

					</div>
					<div class="controls subcollection" v-if="isAdmin">
						<multiselect @close="updateSubCollectionAccessGroups()"
							@remove="updateSubCollectionAccessGroups()"
							v-model="selectedSubCollection.accessGroups"
							:options="accessGroupIds"
							:multiple="true"
							:close-on-select="false"
							:clear-on-select="false"
							:preserve-search="true"
							placeholder="Select access groups"
							label="name"
							track-by="id">
						</multiselect>
					</div>

					<p class="collection-detail-subtitle" v-if="!selectedSubCollectionAllowed">Full access is available to <span @click="$router.push('/for-teachers/2')">Licensed Teachers</span> only. Please <span @click="$router.push({ name: 'contact', params: { reason: reason.kinderBeatLicence, scroll: true }})">contact us</span> to apply for a licence and/or create an account.</p>

					<div class="link-container">
						<div v-for="(resource, index) in resourcesForSubCollection(selectedSubCollection)" :key="resource.id" >
							<img v-if="!resource.coverUrl" src="@/assets/images/thumbnail.png" @click="userClick(resource)" :class="{ free: resource.free && !selectedSubCollectionAllowed, allowed: selectedSubCollectionAllowed }"/>
							<img v-else :src="resource.coverUrl" @click="userClick(resource)" :class="{ free: resource.free && !selectedSubCollectionAllowed, allowed: selectedSubCollectionAllowed }"/>
							<p class="collection-detail-link" :class="{ free: resource.free && !selectedSubCollectionAllowed, allowed: selectedSubCollectionAllowed, watched: videoIsWatched(resource.id) }" @click="openModal(resource)"><span class="free-icon">Free</span><span>{{ resource.title }}</span></p> <button @click="resourceUp(index)" class="material-icons" v-if="isAdmin">arrow_upward</button><button @click="resourceDown(index)" class="material-icons" v-if="isAdmin">arrow_downward</button>
							<p class="collection-detail-description">{{ resource.description }}</p>
							<p class="collection-detail-date" v-if="resource.date">{{ resource.date | timeSince }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="video-container" v-else>
			<ResourceCollection v-for="(collection, index) in collections" :key="index" :collection="{ ...collection, id: collection.id }" :subCollections="subCollections" :collectionIndex="index" :isAdmin="isAdmin" @select="selectSubCollection($event)" @add="addSubCollection($event)" @remove="removeCollection($event)" @prev="movePrev($event)" @next="moveNext($event)" @refresh="refresh"></ResourceCollection>
		</div>

		<Modal v-if="showModal" @close="closeModal">
			<div class="video-modal-container" v-if="selectedResource">
				<div class="video-modal-sidebar">
					<p class="video-detail-title">{{ selectedResource.title }}</p>
					<p class="video-detail-file" v-if="selectedResource.type == 'file'" >{{ selectedResource.file }}</p>
					<p class="video-detail-description">{{ selectedResource.description }}</p>
					
					<div class="sidebar-bottom">
						<button class="video-add-bag button-round" v-if="isAdmin" @click="editResource">Edit</button>
						<button class="video-add-bag button-round" v-if="isAdmin" @click="removeResource">Remove</button>
						<button class="video-add-bag button-round" v-if="selectedResource.type == 'video'" @click="watchVideo(selectedResource.id)">Watch</button>
						<button class="video-add-bag button-round" v-else @click="downloadFile(selectedResource)">Download</button>
					</div>
				</div>

				<div class="video-modal-detail" v-if="selectedResource.coverUrl">
					<img :src="selectedResource.coverUrl">
				</div>
			</div>

			<div class="add-resource-modal-container" v-else>
				<p class="heading">{{ (editingId ? "Edit" : "New") + " Resource"}}</p>

				<div class="resource-form-container">
					<input type="file" ref="resourceInput" style="display: none" @change="previewResource">
					<input type="file" ref="coverInput" style="display: none" @change="previewCover">

					<div class="select-container">
						<label for="access" class="for-select">Access</label>
						<select name="access" id="access" v-model="newResource.access">
							<option value="free" selected>Free</option>
							<option value="paid">Paid</option>
						</select>
					</div>

					<input type="text" placeholder="Title" v-model="newResource.title">
					<textarea name="description" rows="4" placeholder="Description" v-model="newResource.description"></textarea>
					<input type="text" placeholder="Vimeo URL" v-model="newVideoUrl" v-if="newResource.type == 'video'">

					<p class="info-bold nomargin" v-if="newResource.type == 'file'">Resource file:</p>
					<button class="button-round" @click="uploadResource" v-if="newResource.type == 'file'">{{ this.newResource.file ? "Remove: " + this.newResource.file : (this.uploadValue != 0 ? `${this.uploadValue.toFixed()}%` : 'Upload File') }}</button>
					
					<p class="info-bold">Cover file:</p>
					<button class="button-round" @click="uploadCover">{{ this.newResource.coverFile ? "Remove: " + this.newResource.coverFile : (this.coverUploadValue != 0 ? `${this.coverUploadValue.toFixed()}%` : 'Upload Cover Image') }}</button>
				
					<p class="info" v-if="showError">Please make sure all required fields are filled out.</p>

					<div class="submit-container">
						<button class="button-round" type="submit" :disabled="submitPrevent" @click="saveResource">Save</button>
					</div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import ContactReasons from '@/contact.js';
import ResourceCollection from '@/components/ResourceCollection.vue';
import Modal from '@/components/Modal.vue';
import { mapState, mapGetters } from 'vuex';
import { db, storage } from '@/firebase'
import firebase from 'firebase/app'
import Multiselect from 'vue-multiselect'
import moment from 'moment'

export default {
	props: [
		"type",
		"title",
		"subtitle",
		"showVideo",
		"pCollection",
		"pSubCollection"
	],
	components: {
		ResourceCollection,
		Modal,
		Multiselect
	},
	data() {
		return {
			selectedSubCollectionId: null,
			showModal: false,
			selectedResource: null,
			collectionIdToAdd: null,
			selectedCollection: null,
			isEditingSubCollectionName: false,
			subCollectionNameEdit: "",
			newResource: {
				access: "free",
				type: this.resourceType,
				title: "",
				description: "",
				videoId: "",
				file: null,
				coverFile: null
			},
			editingId: null,
			needsDeleting: [],
			oldRef: null,
			coverOldRef: null,
			resourceData: null,
			coverResourceData: null,
			newVideoUrl: "",
			showError: false,
			submitPrevent: false,
			uploadValue: 0,
			coverUploadValue: 0,
			storageRef: null,
			coverStorageRef: null
		}
	},
	computed: {
		...mapState('resources', ['resources', 'subCollections', 'accessGroups']),
		...mapState('resources', { 'collectionsUnfiltered': 'collections' }),
		...mapGetters('user', ['isLoggedIn']),
		...mapGetters('teachers', ['loggedInTeacher', 'teacherSubCollections', 'teacherWatchedVideos']),
		...mapState('user', ['isAdmin']),
		...mapGetters('resources', ['videoURL', 'videoCaption']),
		videoURLEdit: {
			get: function() {
				if (this.videoURL) {
					return `https://vimeo.com/${this.videoURL}`
				} else {
					return "";
				}
			},
			set: function(newVal) {
				const re = /https:\/\/vimeo\.com\/(\d+)/g
				let groups = re.exec(newVal);
				
				if (groups.length != 2) {
					return;
				}
				
				let videoId = groups[1];
				
				db.collection("settings").doc("resources").update({
					videoURL: videoId
				}).then(() => {
					this.$toast.open("Saved");
				});
			}
		},
		videoCaptionEdit: {
			get: function() {
				return this.videoCaption
			},
			set: function(newVal) {
				db.collection("settings").doc("resources").update({
					videoCaption: newVal
				})
			}
		},
		selectedSubCollectionAllowed() {
			return this.isAdmin || this.teacherSubCollections.includes(this.selectedSubCollectionId);
		},
		selectedSubCollection() {
			return this.subCollections.find(s => s.id === this.selectedSubCollectionId);
		},
		collections() {
			return this.collectionsUnfiltered.filter(c => c.page === this.type).sort((a, b) => a.position - b.position)
		},
		reason() {
			return ContactReasons;
		},
		resourceType() {
			return this.type === "video" ? "video" : "file"
		},
		collectionTitle() {
			return this.selectedCollection.name + {
				"video": " Training Videos",
				"marketing": " Marketing Materials",
				"notes": "",
				"downloads": ""
			}[this.type]
		},
		accessGroupIds() {
			if (this.accessGroups) {
				return this.accessGroups.map((val) => ({ name: val.name, id: val.id }));
			} else {
				return [];
			}
		}
	},
	methods: {
		videoIsWatched(video) {
			return this.teacherWatchedVideos.includes(video);
		},
		updateSubCollectionAccessGroups() {
			db.collection('subCollections').doc(this.selectedSubCollectionId).update({
				accessGroups: this.selectedSubCollection.accessGroups
			}).then(() => {
				this.$toast.open("Saved");
			});
		},
		addAccessGroup() {
			var name = window.prompt("Access Group Name")

			if (name != null) {
				db.collection('settings').doc('resources').collection('accessGroups').add({
					name: name
				})
			}

		},
		editAccessGroup(group) {
			var name = window.prompt("Access Group Name", group.name)

			if (name != null) {
				if (this.accessGroups.includes(name)) {
					this.$toast.warning("Group already exists")
				} else {
					db.collection('settings').doc('resources').collection('accessGroups').doc(group.id).update({
						name: name
					});
				}
			}

		},
		deleteAccessGroup(group) {
			db.collection('settings').doc('resources').collection('accessGroups').doc(group.id).delete()
		},
		editSubCollectionName() {
			if (this.isEditingSubCollectionName) {
				db.collection('subCollections').doc(this.selectedSubCollectionId).update({
					name: this.subCollectionNameEdit
				}).then(() => {
					this.$toast.open("Saved");
				});
			} else {
				this.subCollectionNameEdit = this.selectedSubCollection.name;
			}

			this.isEditingSubCollectionName = !this.isEditingSubCollectionName;
		},
		back() {
			this.selectedSubCollectionId = null;
			this.selectedCollection = null;
		},
		previewResource(event) {
            this.uploadValue = 0;
            this.resourceData = event.target.files[0];
            this.onUpload()
		},
		previewCover(event) {
            this.coverUploadValue = 0;
            this.coverResourceData = event.target.files[0];
            this.onUploadCover()
		},
		onUpload() {
			if (this.storageRef != null) {
                this.storageRef.delete();
            }

			this.submitPrevent = true;
			this.newResource.file = null;
            this.storageRef = storage.ref(`resources/${this.resourceData.name}`);
            this.storageRef.put(this.resourceData).on('state_changed', snapshot => {
                this.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            }, error => {
                console.log(error.message);
				this.submitPrevent = false;
            }, () => {
				this.submitPrevent = false;
                this.uploadValue = 100;
				this.$set(this.newResource, 'file', this.resourceData.name);
				
				this.needsDeleting.push(this.storageRef);

                setTimeout(() => {
                    this.uploadValue = 0;
                }, 1000);
            });
		},
		onUploadCover() {
			if (this.coverStorageRef != null) {
                this.coverStorageRef.delete();
            }

			this.submitPrevent = true;
			this.newResource.coverFile = null;
            this.coverStorageRef = storage.ref(`resourceCovers/${this.coverResourceData.name}`);
            this.coverStorageRef.put(this.coverResourceData).on('state_changed', snapshot => {
                this.coverUploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            }, error => {
                console.log(error.message);
				this.submitPrevent = false;
            }, () => {
				this.submitPrevent = false;
                this.coverUploadValue = 100;
				this.coverStorageRef.getDownloadURL().then(url => {
					this.$set(this.newResource, 'coverFile', this.coverResourceData.name);
					this.$set(this.newResource, 'coverUrl', url);
                });
				
				this.needsDeleting.push(this.coverStorageRef);

                setTimeout(() => {
                    this.coverUploadValue = 0;
                }, 1000);
            });
		},
		uploadResource() {
			if (this.newResource.file) {
				this.$set(this.newResource, 'file', null);
				this.storageRef = null;
			} else {
				this.$refs.resourceInput.click();
			}
		},
		uploadCover() {
			if (this.newResource.coverFile) {
				this.$set(this.newResource, 'coverFile', null);
				this.coverStorageRef = null;
			} else {
				this.$refs.coverInput.click();
			}
		},
		selectSubCollection(event) {
			this.selectedCollection = event.collection;
			this.selectedSubCollectionId = event.subCollection.id;

			window.setTimeout(() => {
				this.$scrollTo('#resource-content', { offset: -170 });
			}, 100)
		},
		openModal(resource) {
			if (!this.selectedSubCollectionAllowed && !resource.free) {
				return;
			}

			this.selectedResource = resource;
			
			this.showModal = true;
		},
		userClick(resource) {
			if (!this.selectedSubCollectionAllowed && !resource.free) {
				return;
			}

			if (resource.type == 'video') {
				this.watchVideo(resource.id);
			} else {
				this.downloadFile(resource)
			}
		},	
		closeModal() {
			this.selectedResource = null;
			this.editingId = null;
			this.showModal = false;

			this.newResource = {
				access: "free",
				type: this.resourceType,
				title: "",
				description: "",
				videoId: "",
				file: null,
				coverFile: null
			}
			this.newVideoUrl = ""

			this.deleteUnusedResources();
		},
		subCollectionsForCollection(index) {
			return this.collections[index].subCollections.map(x => this.subCollections.find(s => s.id === x))
		},
		resourcesForSubCollection(subCollection) {
			return subCollection.items.map(x => {
				var resource = this.resources.find(r => r.id === x)

				if (typeof resource == 'undefined') {
					console.log(`Can't find resource ${x} for subcollection ${subCollection.id}`)
				} else {
					if (resource.coverFile) {
						storage.ref(`resourceCovers/${resource.coverFile}`).getDownloadURL().then((url) => {
							this.$set(resource, "coverUrl", url);
						})
					}
				}
				
				return resource
			}).filter(x => typeof x != 'undefined')
		},
		watchVideo(video) {
			this.$router.push({ name: 'watch', query: { id: video, collection: this.selectedCollection.id, subCollection: this.selectedSubCollectionId }})
		},
		login() {
			this.$router.push({ path: '/login?next=resources' })
		},
		logout() {
			this.$router.push({ name: 'logout' })
		},
		addCollection() {
			db.collection('resourceCollections').add({
				name: "Untitled Collection",
				colour: "#00a7e5",
				page: this.type,
				position: this.collections.reduce((prev, curr) => Math.max(prev, curr.position), 0) + 1,
				subCollections: []
			})
		},
		addSubCollection(id) {
			db.collection('subCollections').add({
				name: "Untitled Subcollection",
				items: []
			}).then(newSubCollection => {
				db.collection('resourceCollections').doc(id).update({
					subCollections: firebase.firestore.FieldValue.arrayUnion(newSubCollection.id)
				})
			})			
		},
		saveResource() {
			if (this.newResource.type == "video") {
				const re = /https:\/\/vimeo\.com\/(\d+)/g
				let groups = re.exec(this.newVideoUrl);
				
				if (groups.length != 2) {
					return;
				}
				
				let videoId = groups[1];
				this.newResource.videoId = videoId;
			}

			this.needsDeleting = this.needsDeleting.filter(val => val != this.storageRef && val != this.coverStorageRef);

			if (this.oldRef != null && this.oldRef != this.storageRef) {
				this.needsDeleting.push(this.oldRef);
			}

			if (this.coverOldRef != null && this.coverOldRef != this.coverStorageRef) {
				this.needsDeleting.push(this.coverOldRef);
			}

			var newData = {
				free: this.newResource.access === "free",
				type: this.newResource.type,
				title: this.newResource.title,
				description: this.newResource.description,
				videoId: this.newResource.videoId,
				date: new Date()
			}

			if (this.newResource.file) {
				newData.file = this.newResource.file;
			} else {
				newData.file = null;
			}

			if (this.newResource.coverFile) {
				newData.coverFile = this.newResource.coverFile;
			} else {
				newData.coverFile = null;
			}

			if (this.editingId) {
				db.collection('resources').doc(this.editingId).update(newData).then(() => {
					this.$toast.open("Saved");
					this.closeModal();
				})
			} else {
				db.collection('resources').add(newData).then((result) => {
					return db.collection('subCollections').doc(this.selectedSubCollectionId).update({
						items: firebase.firestore.FieldValue.arrayUnion(result.id)
					})
				}).then(() => {
					this.$toast.open("Saved");
					this.closeModal();
				})
			}
		},
		editResource() {
			this.newResource = { ...this.selectedResource, access: this.selectedResource.free ? "free" : "paid" };
			this.editingId = this.selectedResource.id;

			this.$set(this.newResource, 'file', this.selectedResource.file);
			this.$set(this.newResource, 'coverFile', this.selectedResource.coverFile);

			if (this.newResource.file) {
				this.storageRef = storage.ref(`resources/${this.newResource.file}`);
				this.oldRef = this.storageRef;
			}

			if (this.newResource.coverFile) {
				this.coverStorageRef = storage.ref(`resourceCovers/${this.newResource.coverFile}`);
				this.coverOldRef = this.coverStorageRef;
			}

			this.selectedResource = null;
			this.newVideoUrl = "https://vimeo.com/" + this.newResource.videoId;
		},
		removeResource() {
			if (window.confirm("Are you sure you want to delete this resource? This can't be undone")) {
				if (this.selectedResource.file) {
					console.log(this.selectedResource.file);
					// TODO: Remove file
				}

				db.collection('resources').doc(this.selectedResource.id).delete().then(() => {
					return db.collection('subCollections').doc(this.selectedSubCollectionId).update({
						items: firebase.firestore.FieldValue.arrayRemove(this.selectedResource.id)
					})
				}).then(() => {
					this.closeModal();
				})
			}
		},
		removeCollection(collectionId) {
			if (window.confirm("Are you sure you want to delete this collection including all subcollections and resources? This can't be undone")) {
				var collectionRef = db.collection('resourceCollections').doc(collectionId)
				
				collectionRef.get().then(async (collection) => {
					for (var subCollectionId of collection.data().subCollections) {
						var subCollectionRef = db.collection('subCollections').doc(subCollectionId)
						
						await subCollectionRef.get().then(async (subCollection) => {
							for (var resource of subCollection.data().items) {
								await db.collection('resources').doc(resource).delete()
							}
						})

						await subCollectionRef.delete()
					}
					
					return collectionRef.delete()
				}).then(() => {
					this.closeModal();
				})
			}
		},
		removeSubCollection(subCollectionId) {
			// TODO: Remove all the files
			if (window.confirm("Are you sure you want to delete this subcollection including all resources? This can't be undone")) {
				var subCollectionRef = db.collection('subCollections').doc(subCollectionId)

				var collectionId = this.selectedCollection.id
				
				return subCollectionRef.get().then(async (subCollection) => {
					for (var resource of subCollection.data().items) {
						await db.collection('resources').doc(resource).delete()
					}
					
					return subCollectionRef.delete().then(() => {
						return db.collection('resourceCollections').doc(collectionId).update({
							subCollections: firebase.firestore.FieldValue.arrayRemove(subCollectionId)
						})
					})
				})
			}
		},
		addResource() {
			this.showModal = true;
		},
		downloadFile(resource) {
			if (resource.file) {
				const storageRef = storage.ref(`resources/${resource.file}`);
				storageRef.getDownloadURL().then(url => {
					window.open(url, "_self");
				}).catch(err => {
					console.error(err);
				})
			}
		},
		deleteUnusedResources() {
			this.needsDeleting.forEach(ref => {
				ref.delete();
			});

			this.needsDeleting = [];
			this.storageRef = null;
			this.oldRef = null;
			this.coverStorageRef = null;
			this.coverOldRef = null;
		},
		movePrev(collectionId) {
			let index = this.collections.findIndex(a => a.id === collectionId)
			
			// Make sure this isn't the first collection
			if (index > 0) {
				let position = this.collections[index].position
				let prevPosition = this.collections[index - 1].position
				let prevId = this.collections[index - 1].id

				db.collection('resourceCollections').doc(collectionId).update({
					position: prevPosition
				}).then(() => {
					return db.collection('resourceCollections').doc(prevId).update({
						position: position
					})
				}).then(() => {
					this.$toast.open("Saved");
				});
			}
		},
		moveNext(collectionId) {
			let index = this.collections.findIndex(a => a.id === collectionId)
			
			// Make sure this isn't the last collection
			if (index < this.collections.length - 1) {
				let position = this.collections[index].position
				let nextPosition = this.collections[index + 1].position
				let nextId = this.collections[index + 1].id

				db.collection('resourceCollections').doc(collectionId).update({
					position: nextPosition
				}).then(() => {
					return db.collection('resourceCollections').doc(nextId).update({
						position: position
					})
				}).then(() => {
					this.$toast.open("Saved");
				});
			}
		},
		resourceUp(index) {
			if (index == 0) {
				return;
			}

			var currentArray = this.selectedSubCollection.items

			var indexEl = currentArray[index]
			currentArray[index] = currentArray[index - 1]
			currentArray[index - 1] = indexEl

			db.collection('subCollections').doc(this.selectedSubCollectionId).update({
				items: currentArray
			})
		},
		resourceDown(index) {
			var currentArray = this.selectedSubCollection.items

			if (index == currentArray.length - 1) {
				return;
			}

			var indexEl = currentArray[index]
			currentArray[index] = currentArray[index + 1]
			currentArray[index + 1] = indexEl

			db.collection('subCollections').doc(this.selectedSubCollectionId).update({
				items: currentArray
			})
		},
		refresh() {
		}
	},
	mounted() {
		this.$store.dispatch("resources/setupResourceBinds")
		this.$store.dispatch("teachers/bindList")

		this.newResource.type = this.resourceType

		window.onbeforeunload = () => {
			this.deleteUnusedResources();
        }

		if (this.pCollection) {
			this.selectedCollection = this.collections.find(c => c.id === this.pCollection);
			if (this.selectSubCollection) {
				this.selectedCollection = { ...this.selectedCollection, id: this.selectedCollection.id};
				this.selectedSubCollectionId = this.pSubCollection;
				window.setTimeout(() => {
					this.$scrollTo('#resource-content', { offset: -170 });
				}, 300)
			}
		}
	},
	filters: {
		timeSince(date) {
			if (!date) {
				return ''
			}

			return moment(date.toDate()).fromNow()
		}
	}
}
</script>